.impressum__article {
	margin: 0 auto;
	max-width: 76%;
	padding: 1em;
}

.impressum__article h2 {
	color: var(--color-primary);
	font-size: 1.5em;
	margin: 0.83em 0;
}

.impressum__article a {
	color: var(--color-primary);
	text-decoration: underline;
}

.impressum__article p {
	font-size: 0.9rem;
	color: var(--color-light);
	margin: 2rem 0;
}

/* =========== MEDIA QUERIES (SMALL DEVICES) ============= */

@media screen and (max-width: 600px) {
	.impressum__article {
		width: var(--container-width-sm);
	}
	.impressum__article > h2 {
		margin-bottom: 2rem;
	}
}

@media screen and (max-width: 475px) {
	.impressum__article {
		width: 90%; /* oder ein anderer geeigneter Wert */
		max-width: 100%; /* um sicherzustellen, dass das Element nicht breiter als der Bildschirm ist */
		margin: 0 auto; /* um das Element horizontal zu zentrieren */
	}

	.impressum__article h2 {
		font-size: 1.2rem;
	}

	.impressum__article p {
		font-size: 0.9rem;
	}
}
