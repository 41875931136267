/* poppins-300 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	src: url('./fonts/poppins-v20-latin-300.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/poppins-v20-latin-300.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/poppins-v20-latin-300.woff2') format('woff2'),
		/* Super Modern Browsers */ url('./fonts/poppins-v20-latin-300.woff')
			format('woff'),
		/* Modern Browsers */ url('./fonts/poppins-v20-latin-300.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/poppins-v20-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-regular - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: url('./fonts/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/poppins-v20-latin-regular.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/poppins-v20-latin-regular.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('./fonts/poppins-v20-latin-regular.woff') format('woff'),
		/* Modern Browsers */ url('./fonts/poppins-v20-latin-regular.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	src: url('./fonts/poppins-v20-latin-500.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/poppins-v20-latin-500.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/poppins-v20-latin-500.woff2') format('woff2'),
		/* Super Modern Browsers */ url('./fonts/poppins-v20-latin-500.woff')
			format('woff'),
		/* Modern Browsers */ url('./fonts/poppins-v20-latin-500.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/poppins-v20-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: url('./fonts/poppins-v20-latin-600.eot'); /* IE9 Compat Modes */
	src: local(''),
		url('./fonts/poppins-v20-latin-600.eot?#iefix')
			format('embedded-opentype'),
		/* IE6-IE8 */ url('./fonts/poppins-v20-latin-600.woff2') format('woff2'),
		/* Super Modern Browsers */ url('./fonts/poppins-v20-latin-600.woff')
			format('woff'),
		/* Modern Browsers */ url('./fonts/poppins-v20-latin-600.ttf')
			format('truetype'),
		/* Safari, Android, iOS */
			url('./fonts/poppins-v20-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}

* {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	box-sizing: border-box;
	list-style: none;
	text-decoration: none;
}

:root {
	/* Original Color Schema */
	/* --color-bg: #1f1f38;
	 --color-bg-variant: #2c2c6c;
	--color-primary: #4db5ff;
	--color-primary-variant: rgba(77, 181, 255, 0.4);
	--color-white: #fff;
	--color-light: rgba(255, 255, 255, 0.6);
	--color-lighter: rgba(255, 255, 255, 0.9); */

	/* New Color Schema 01*/
	--color-bg: #1b1b20;
	--color-bg-variant: #262635;
	--color-primary: #4db5ff;
	--color-primary-variant: rgba(77, 181, 255, 0.4);
	--color-white: #fff;
	--color-light: rgba(255, 255, 255, 0.6);
	--color-lighter: rgba(255, 255, 255, 0.9);

	--transition: all 400ms ease;

	--container-width-lg: 75%;
	--container-width-md: 86%;
	--container-width-sm: 90%;

	--font-family: 'Poppins', sans-serif;
}

html {
	scroll-behavior: smooth;
}

::-webkit-scrollbar {
	display: none;
}

body {
	font-family: var(--font-family);
	background: var(--color-bg);
	color: var(--color-white);
	line-height: 1.7;
	background-image: url('./assets/bg-texture.png');
}

/* =========== GENERAL STYLES ============= */

.container {
	width: var(--container-width-lg);
	margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: 500;
}

h1 {
	font-size: 2.5rem;
}

section {
	margin-top: 8rem;
	/* height: 100vh; */
}

section > h2,
section > h5 {
	text-align: center;
	color: var(--color-light);
}

section > h2 {
	color: var(--color-primary);
	margin-bottom: 3rem;
}

.text-light {
	color: var(--color-light);
}

a {
	color: var(--color-primary);
	transition: var(--transition);
}

a:hover {
	color: var(--color-white);
}

.btn {
	width: max-content;
	display: inline-block;
	color: var(--color-primary);
	padding: 0.75rem 1.2rem;
	border-radius: 0.4rem;
	cursor: pointer;
	border: 1px solid var(--color-primary);
	transition: var(--transition);
}

.btn:hover {
	background: var(--color-white);
	color: var(--color-bg);
	border-color: transparent;
}

.btn-primary {
	background: var(--color-primary);
	color: var(--color-bg);
}

img {
	display: block;
	width: 100%;
	object-fit: cover;
}

/* =========== MEDIA QUERIES (MEDIUM DEVICES) ============= */

@media screen and (max-width: 1024px) {
	.container {
		width: var(--container-width-md);
	}
	section {
		margin-top: 6rem;
	}
}

/* =========== MEDIA QUERIES (SMALL DEVICES) ============= */

@media screen and (max-width: 600px) {
	.container {
		width: var(--container-width-sm);
	}
	section > h2 {
		margin-bottom: 2rem;
	}
}
