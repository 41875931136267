.portfolio__container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 2.5rem;
}

.portfolio__item {
	display: flex;
	flex-direction: column;
	background: var(--color-bg-variant);
	padding: 1.2rem;
	border-radius: 2rem;
	border: 1px solid transparent;
	transition: var(--transition);
}

.portfolio__item:hover {
	background: transparent;
	border-color: var(--color-primary-variant);
}

.portfolio__item-image {
	border-top-left-radius: 1.5rem;
	border-top-right-radius: 1.5rem;
	overflow: hidden;
	width: 100%;
	height: auto;
	padding-bottom: 56.25%; /* For an aspect ratio of 16:9. Adjust as needed. */
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.portfolio__item-image-1 {
	background-image: url('../../assets/projects/Iphone_dark_B1000px_16_9.jpg');
}
.portfolio__item-image-2 {
	background-image: url('../../assets/projects/DevFlow_dark_B1000px_16_9.jpg');
}

.portfolio__item-image-2:hover {
	background-image: url('../../assets/projects/DevFlow_light_B1000px_16_9.jpg');
}

.portfolio__item-image-3 {
	background-image: url('../../assets/projects/Yoom_B1000_16_9.jpg');
}

.portfolio__item-image-4 {
	background-image: url('../../assets/projects/Place_to_Stay_dark_B1000px_16_9.jpg');
}

.portfolio__item-image-4:hover {
	background-image: url('../../assets/projects/Place_to_Stay_light_B1000px_16_9.jpg');
}

.portfolio__item h4 {
	min-height: 72px;
	margin-top: 1rem;
}

.portfolio__item-technologies {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	margin-bottom: 1rem;
}

.portfolio__item-description {
	line-height: 1.1rem;
	margin-bottom: 2rem;
}

.portfolio__item-cta {
	display: flex;
	gap: 1rem;
	margin-bottom: 1rem;
}

/* =========== MEDIA QUERIES (MEDIUM DEVICES) ============= */

/* max-width means  */
@media screen and (max-width: 1600px) {
	.portfolio__container {
		grid-template-columns: 1fr 1fr;
		gap: 1.2rem;
	}
}
/* =========== MEDIA QUERIES (MEDIUM DEVICES) ============= */

@media screen and (max-width: 1400px) {
	.portfolio__container {
		grid-template-columns: 1fr;
		gap: 1rem;
	}
	.portfolio__item h3 {
		min-height: 80px;
	}
}

/* =========== MEDIA QUERIES (SMALL DEVICES) ============= */

/* @media screen and (max-width: 600px) {
	.portfolio__container {
		grid-template-columns: 1fr;
		gap: 1rem;
	}
	.portfolio__item h3 {
		min-height: 80px;
	}
} */
