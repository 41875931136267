header {
	height: 100vh;
	padding-top: 7rem;
	overflow: auto;
}

.container__header {
	text-align: center;
	height: 100%;
	position: relative;
}

/* =========== ME ============= */

.me {
	background: linear-gradient(var(--color-primary), transparent);
	width: 22rem;
	height: 32rem;
	position: absolute;
	/* Setzt bei position absolut das Elemeent in die Mitte. - Hälfte der Breite */
	left: calc(50% - 11rem);
	margin-top: 4rem;
	border-radius: 12rem 12rem 0 0;
	overflow: hidden;
	padding: 3rem 0 0 0;
}

/* =========== SCROLL DOWN ============= */

.scroll__down {
	position: absolute;
	right: -2.3rem;
	bottom: 5rem;
	transform: rotate(90deg);
	font-weight: 300;
	font-size: 0.9rem;
}

/* =========== MEDIA QUERIES (MEDIUM DEVICES) ============= */

@media screen and (max-width: 1024px) {
	header {
		height: 68vh;
	}
}

/* =========== MEDIA QUERIES (SMALL DEVICES) ============= */

@media screen and (max-width: 600px) {
	header {
		height: 100vh;
	}
	.scroll__down {
		display: none;
	}
}
